import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../Services/Firebase/firebase-config";
import Gallery from "react-photo-gallery";
import { Box, Heading, Grid, Button, GridItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import bg from "../logo/bg.png";

function Dispay() {
  // Create a reference under which you want to list
  const { state } = useLocation();
  const [img, setImg] = useState([]);
  const listRef = ref(storage, state.state["ORG_ID"]);
  const org = state.state["index"];

  useEffect(() => {
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          const starsRef = ref(storage, itemRef._location.path);

          getDownloadURL(starsRef).then((url) => {
            if (img.indexOf(url) !== -1) return;
            setImg((oldArray) => [
              ...oldArray,
              {
                src: url,
                width: 3,
                height: 3,
              },
            ]);
          });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }, []);

  return (
    <Box w="100%" h="100vh" p={4}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem>
          <Link
            to={
              "/?lat=" +
              state.state["Lat"] +
              "&lon=" +
              state.state["Lon"] +
              "&org=" +
              org
            }
          >
            <Button>Back To Map</Button>
          </Link>
        </GridItem>
        <GridItem justifySelf={"center"}>
          <Heading as="h1" size="lg" color="black">
            {state.state["Name"]}
          </Heading>
        </GridItem>
      </Grid>

      <Box mt="15px">
        <Gallery photos={img} />
      </Box>
    </Box>
  );
}

export default Dispay;
