import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBzzYoFvcwp1ASd4MOg2jB4UfXKA5H7C8I",
  authDomain: "lunarcmap.firebaseapp.com",
  projectId: "lunarcmap",
  storageBucket: "lunarcmap.appspot.com",
  messagingSenderId: "958742928657",
  appId: "1:958742928657:web:e1f11b93ea680d489080b0",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { app, storage };
