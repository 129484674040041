import { Routes, Route, useNavigate } from "react-router-dom";

import Home from "../Pages/Home";
import Dispay from "../Pages/Dispay";
export default function RootNavigation() {
  let navigate = useNavigate();

  return (
    <Routes>
      <Route path="*" element={<Home title="Home" />} />
      <Route path="/gallery" element={<Dispay title="Dispay" />} />
    </Routes>
  );
}
