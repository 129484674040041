import * as React from "react";
import { Image } from "@chakra-ui/react";

const pinStyle = {
  fill: "#000",
};

function Pin({ size = 20 }) {
  return (
    <svg height={size} viewBox="-4 0 36 36" style={pinStyle}>
      <g stroke-width="0"></g>
      <g stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g
          id="Vivid.JS"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
            <g id="Icons" transform="translate(37.000000, 169.000000)">
              <g id="map-marker" transform="translate(78.000000, 468.000000)">
                <g transform="translate(10.000000, 6.000000)">
                  <path
                    d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"
                    id="Shape"
                    fill="#FF6E6E"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#005716"
                    fill-rule="nonzero"
                    cx="14"
                    cy="14"
                    r="7"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      {/* <Image
        boxSize="80%"
        objectFit="cover"
        src="https://firebasestorage.googleapis.com/v0/b/lunarc-d2fba.appspot.com/o/projectfuel-logo.webp?alt=media&token=31167343-6909-4f0e-a2c3-b5f4ec07bd91"
      /> */}
    </svg>
  );
}

export default Pin;
